import Keycloak from 'keycloak-js'
import ConfigService from '../Infrastructure/ConfigService/ConfigService'

const keycloak = new Keycloak({
  url: ConfigService.config.REACT_APP_KEYCLOAK_URL,
  realm: ConfigService.config.REACT_APP_KEYCLOAK_REALM!,
  clientId: ConfigService.config.REACT_APP_KEYCLOAK_CLIENT_ID!,
})

export const initOptions = {}

export default keycloak
